const params: Record<string, string> = {};
for (const it of location.search.substring(1).split('&')) {
	const index = it.indexOf('=');
	if (index <= 0) { continue; }
	params[decodeURIComponent(it.substring(0, index))]
		= decodeURIComponent(it.substring(index + 1));
}
export default params;
export const { code, state, appid } = params;
export const custom = Boolean(params.custom);
