import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-41c7b0b1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 2,
  class: "btn btn-block btn-sm login-btn-item"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.loginLoad)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($setup.tt('Logging in on WeChat...')), 1 /* TEXT */))
    : ($setup.officials)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList($setup.officials, ({ name, appid, agentid, title, login_url }) => {
          return (_openBlock(), _createBlock($setup["Btn"], {
            appid: appid,
            name: name,
            agentid: agentid,
            title: title,
            only: $setup.officials.length === 1,
            loginUrl: login_url
          }, null, 8 /* PROPS */, ["appid", "name", "agentid", "title", "only", "loginUrl"]))
        }), 256 /* UNKEYED_FRAGMENT */))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($setup.tt('Getting WeChat login information...')), 1 /* TEXT */))
}