import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cba70b38"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("a", {
    href: $setup.url,
    class: "btn login-btn-item btn-block btn-default btn-sm btn-login-option"
  }, _toDisplayString($setup.tt('Wechat')) + _toDisplayString(!$props.only && $props.title ? `(${$props.title})` : '') + _toDisplayString($setup.tt('Login')), 9 /* TEXT, PROPS */, _hoisted_1))
}