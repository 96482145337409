<template>
	<div v-if="loginLoad">{{ tt('Logging in on WeChat...') }}</div>
	<Btn
		v-else-if="officials"
		v-for="{ name, appid, agentid, title, login_url } in officials"
		:appid="appid"
		:name="name"
		:agentid="agentid"
		:title="title"
		:only="officials.length === 1"
		:loginUrl="login_url"
	/>
	<div v-else class="btn btn-block btn-sm login-btn-item">
		{{ tt('Getting WeChat login information...') }}
	</div>
</template>
<script lang="ts" setup>
import {onMounted, ref} from 'vue';

import Btn from './Button.vue';
import {getOfficials, loginByWechatCode, Official, State} from './service';
import * as params from './params';

const tt = __;

const loginLoad = ref(false);

function loginReload() {
	loginLoad.value = true;
	location.reload();
}

const loading = ref(true);
const {code} = params;

const officials = ref<Official[]>();

function showError(t: string) {
	frappe.show_alert(__('This WeChat account has not been bound yet. Please bind WeChat after logging in first'));
}
async function init() {
	if (!code) {
		return;
	}
	if (history.replaceState) {
		history.replaceState(history.state, '', '?');
	}
	const state = await loginByWechatCode(
		params.state || params.appid || '',
		code,
		params.custom,
	);
	switch (state) {
		case State.unbind:
			return showError(__('This WeChat account has not been bound yet. Please bind WeChat after logging in first'));
		case State.invalid:
			return showError(__('Invalid Login Information'));
		case State.appid:
			return showError(__('This WeChat Is Not Supported'));
		case State.ok:
			return loginReload();
		case State.logged:
			return loginReload();
	}
}

onMounted(async () => {
	try {
		const data = await getOfficials();
		await init();
		officials.value = data;
	} finally {
		loading.value = false;
	}
});
</script>

<style scoped>
.login-btn-item {
	margin-top: 16px;
}
</style>
