
export enum State {
	/** 自动登陆 */
	ok = 0,
	/** 要求用户登陆 */
	unbind = -1,
	/** key 无效 */
	invalid = -2,
	/** 微信用户与当前用户不同 */
	logged = -3,
	/** 无效的 appid */
	appid = -4,
	/** 未知错误 */
	unknown = -100,
	/** 请求错误 */
	error = -1000,
}


export async function loginByWechatCode(name: string, code: string, custom: boolean) {
	try {
		const params: Record<string, any> = { name, code };
		if (custom) { params.custom = 1; }
		const data = await frappe.call<{
			message: State,
		}>('guigu_wechat.bind.login_by_wechat_code', params);
		if (!data) { return State.error; }
		return data.message;
	} catch {
		return State.error;
	}
}

export interface Official {
	appid: string;
	title: string;
	login_url?: string;
}
export async function getOfficials() {
	const data = await frappe.call<{
		message: Official[]
	}>('guigu_wechat.bind.list');
	return data?.message || [];
}
